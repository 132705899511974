<template>
  <div>
    <div class="secondary-nav-container">
      <li>
        <a href="https://www.servace.co.uk/about" class="secondary-nav-links">
          <img
            src="../images/RGBServaceArrow.png"
            alt="About us"
            class="arrow-logos"
            style="transform: rotate(90deg)"
          />
          About Us
        </a>
      </li>
      <li>
        <a
          href="https://www.servace.co.uk/apprenticeships-and-training"
          class="secondary-nav-links"
        >
          <img
            src="../images/GreenServaceArrow.png"
            alt="Apprenticeships and Training"
            class="arrow-logos"
          />
          Apprenticeships & Training
        </a>
      </li>
      <li>
        <a
          href="https://www.servace.co.uk/conference-and-events"
          class="secondary-nav-links"
        >
          <img
            src="../images/PinkServaceArrow.png"
            alt="Conferance and Events"
            class="arrow-logos"
          />
          Conference & Events
        </a>
      </li>
      <li>
        <a
          href="https://www.servace.co.uk/accommodation-and-travel"
          class="secondary-nav-links"
        >
          <img
            src="../images/BlueServaceArrow.png"
            alt="Accommodation and Travel"
            class="arrow-logos"
          />
          Accommodation & Travel
        </a>
      </li>
      <li>
        <a href="https://www.servace.co.uk/contact" class="secondary-nav-links">
          <img
            src="../images/RGBServaceArrow.png"
            alt="About us"
            class="arrow-logos"
            style="transform: rotate(90deg)"
          />
          Contact Us
        </a>
      </li>
    </div>
  </div>
</template>

<style scoped>
.secondary-nav-container {
  position: relative;
  top: -1.5rem;
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  width: 100%;
  background-color: #f2f2f2;
  align-items: center;
}

.secondary-nav-links {
  display: flex;
  text-transform: uppercase;
  text-decoration: none;
  color: grey;
  font-size: 0.75rem;
  cursor: pointer;
}

.secondary-nav-links:hover {
  color: rgb(10, 170, 244);
}

.arrow-logos {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
</style>
