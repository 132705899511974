import { createApp } from 'vue';

import ServaceNav from '../servace-core/servace-nav.vue'

const app = createApp();

app.component('ServaceNav', ServaceNav);


app.mount('#servace-nav');
